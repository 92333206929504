.quickbooks {
    width: 223px;
    height: 36px;
    display: block;
    background: transparent url('../../images/C2QB_green_btn_med_default.svg')
        center top no-repeat;
}

.quickbooks:hover {
    background-image: url('../../images/C2QB_green_btn_med_hover.svg');
}
